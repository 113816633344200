import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
`;

export const Row = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const Heading = styled.h1`
  font-size: 24px;
  margin: 0;
  font-weight: 600;
  color: var(--gray-900);
  line-height: 32px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 4px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  color: var(--gray-600);
`;
