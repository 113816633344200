import { useAuthContext } from '../context/auth.context';

export enum Role {
  Admin = 'admin',
  Manager = 'manager',
  Employee = 'employee',
}

export const useCan = () => {
  const { user, authenticated, loading } = useAuthContext();
  const can = (role: Role | Role[]) => {
    if (!authenticated || !user || loading) {
      return false;
    }

    if (Array.isArray(role)) {
      return role.includes(user?.role.name);
    } else {
      return user?.role.name === role;
    }
  };
  return { can };
};
