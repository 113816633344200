import { PageHeader } from 'src/shared/components/page-header';
import { Wrapper } from './user-details.styles';
import { Tabs } from '@petmate/ui';

export const UserDetails = () => {
  return (
    <Wrapper>
      <PageHeader canGoBack title="User details" />
      <Tabs
        mode="content"
        items={[
          {
            key: 'general-informations',
            label: 'General informations',
            component: <div />,
          },
          { key: 'pets', label: 'Pets', component: <div /> },
          { key: 'addresses', label: 'Addresses', component: <div /> },
          { key: 'payment-cards', label: 'Payment cards', component: <div /> },
          { key: 'subscriptions', label: 'Subscriptions', component: <div /> },
          { key: 'orders', label: 'Orders', component: <div /> },
          { key: 'complaints', label: 'Complaints', component: <div /> },
        ]}
      />
    </Wrapper>
  );
};
