import { IconButton } from '@petmate/ui';
import {
  Actions,
  Description,
  Header,
  Heading,
  Row,
  Wrapper,
} from './page-header.styles';
import { useNavigate } from 'react-router-dom';

export interface PageHeaderProps {
  title: string;
  canGoBack?: boolean;
  actions?: React.ReactNode[];
}

export const PageHeader = ({ title, actions, canGoBack }: PageHeaderProps) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <Header>
        <Row>
          {canGoBack && (
            <IconButton onClick={handleGoBack} name="chevronLeft" />
          )}
          <Heading>{title}</Heading>
        </Row>
        {actions && <Actions>{actions}</Actions>}
      </Header>
      <Description>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio doloremque
        veniam perferendis?
      </Description>
    </Wrapper>
  );
};
