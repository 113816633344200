import styled from 'styled-components';

export const Wrapper = styled.div`
background-color: var(--green-500);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  background-color: var(--white);
  width: 400px;
  padding: 32px;
  border-radius: 8px;
`;

export const LogoImage = styled.img`
  justify-self: center;
  margin: 40px auto;
  width: 200px;
  height: auto;
`;

export const Heading = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
