import { CategoryFormValues } from 'src/categories/forms/category-form/category-form.types';
import { UpdateCategoryVariables } from 'src/categories/gql/mutations/update-category.mutation';
import { GetCategoryData } from 'src/categories/gql/queries/get-category.query';

export type UpdateCategoryVariablesData = UpdateCategoryVariables['data'];

export const formValuesToApiData = (
  values: CategoryFormValues
): UpdateCategoryVariablesData => {
  return {
    name: values.name,
    order: Number(values.order),
    published: Boolean(values.published),
    parentUuid: values.parentUuid,
    slug: values.slug,
    attributes: values.attributes.map((attribute) => ({
      uuid: attribute.uuid,
    })),
    // description: '',
  };
};

export const apiDataToFormValues = (
  data: GetCategoryData
): CategoryFormValues => ({
  name: data.category.name,
  order: data.category.order,
  published: data.category.published,
  parentUuid: data.category.parentUuid,
  slug: data.category.slug || '',
  attributes: data.category.attributes.map((attribute) => ({
    uuid: attribute.uuid,
  })),
});
