import {
  ApolloError,
  TypedDocumentNode,
  gql,
  useMutation,
} from '@apollo/client';
import { useNotifications } from '@petmate/ui';

interface RemoveAttributeData {
  removeAttribute: {
    uuid: string;
  };
}

interface RemoveAttributeVariables {
  uuid: string;
}

export const RemoveAttributeMutation: TypedDocumentNode<
  RemoveAttributeData,
  RemoveAttributeVariables
> = gql`
  mutation RemoveAttributeMutation($uuid: String!) {
    removeAttribute(uuid: $uuid) {
      uuid
    }
  }
`;

export interface RemoveAttributeMutationOptions {
  onCompleted?: (data: RemoveAttributeData) => void;
  onError?: (error: ApolloError) => void;
}

export const useRemoveAttributeMutation = ({
  onCompleted,
  onError,
}: RemoveAttributeMutationOptions) => {
  const { success, error } = useNotifications();

  return useMutation<RemoveAttributeData, RemoveAttributeVariables>(
    RemoveAttributeMutation,
    {
      onCompleted(data) {
        onCompleted?.(data);
        success({
          title: 'Success',
          message: `Attribute removed successfully!`,
        });
      },
      onError(e) {
        onError?.(e);
        error({
          title: 'Error',
          message: `An error occurred while removing the attribute`,
        });
      },
    }
  );
};
