import { PropsWithChildren } from 'react';
import { Wrapper } from './page.styles';

export interface PageProps {
  width?: number;
}

export const Page = ({
  width = 60,
  children,
}: PropsWithChildren<PageProps>) => {
  return <Wrapper $width={width}>{children}</Wrapper>;
};
