import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { GetUsersQuery } from '../../gql/queries/get-users.query.gql';
import { PageHeader } from 'src/shared/components/page-header';
import { useCan } from 'src/auth/hooks/use-can';
import {
  Button,
  ButtonGroup,
  ConfirmModal,
  DataTable,
  Icon,
  IconButton,
  useNotifications,
} from '@petmate/ui';
import { Link } from 'react-router-dom';
import { Wrapper } from './list-users.styles';
import { useState } from 'react';
import { DeleteUserMutation } from '../../gql/mutations/delete-user.mutation.gql';

const columns = [
  {
    label: 'Name',
    accessor: ({ profile }) => `${profile.firstName} ${profile.lastName}`,
  },
  {
    label: 'Email',
    accessor: 'email',
  },
  {
    label: 'Role',
    accessor: ({ role }) => role.displayName,
  },
];

export const Actions = ({ row }) => {
  const client = useApolloClient();
  const [modalOpen, setModalOpen] = useState(false);
  const { error, success } = useNotifications();
  const [deleteUser] = useMutation(DeleteUserMutation, {
    onCompleted() {
      setModalOpen(false);
      client.refetchQueries({ include: [GetUsersQuery] });
      success({
        title: 'Success',
        message: 'User deleted successfully!',
      });
    },
    onError() {
      error({
        title: 'Error',
        message: 'An error occurred while deleting the user',
      });
    },
  });

  const handleModalSubmit = async () => {
    await deleteUser({
      variables: {
        uuid: row.uuid,
      },
    });
  };
  return (
    <ButtonGroup>
      <IconButton
        as={Link}
        to={`/users/${row.uuid}`}
        iconSize={16}
        variant="info"
        name="eye"
      />
      <IconButton
        as={Link}
        to={`/users/${row.uuid}/edit`}
        iconSize={16}
        variant="warning"
        name="edit"
      />
      <IconButton
        iconSize={16}
        variant="error"
        name="trash"
        onClick={() => setModalOpen(true)}
      />
      <ConfirmModal
        cancelText="Nie, zostaw"
        confirmText="Tak, usuń"
        title={`Czy na pewno chcesz usunąć użytkownika: ${row.profile.firstName} ${row.profile.lastName}?`}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleModalSubmit}
        open={modalOpen}
      />
    </ButtonGroup>
  );
};

export const ListUsers = () => {
  const { data, loading, error } = useQuery(GetUsersQuery);
  const { can } = useCan();

  if (loading) {
    return <div>Loading...</div>;
  }

  const { pagination, payload } = data.users;

  return (
    <Wrapper>
      <PageHeader
        title="Users"
        canGoBack
        actions={[
          <Button
            as={Link}
            label="Add user"
            to="/users/create"
            leftComponent={<Icon name="plus" size={20} />}
            size="medium"
          />,
        ]}
      />
      <DataTable
        actions={(row) => <Actions row={row} />}
        pagination={pagination}
        columns={columns}
        data={payload}
      />
    </Wrapper>
  );
};
