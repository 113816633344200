import { Attribute } from 'src/shared/types/attribute.type';
import { Attributes } from './attributes-picker.styles';
import { CheckBox } from 'src/shared/components/checkbox'
import { useFormContext } from 'react-hook-form';
import { ChangeEventHandler } from 'react';
import { CategoryFormValues } from 'src/categories/forms/category-form/category-form.types';
import { useGetAttributesQuery } from 'src/categories/gql/queries/get-attributes.query';

export interface GetAttributesQueryResponse {
  attributes: {
    payload: Attribute[];
  };
}

export interface AttributesResultsProps {
  query: string;
}

export const AttributesResults = () => {
  const { getValues, setValue, control } = useFormContext<CategoryFormValues>();
  const { data, error } = useGetAttributesQuery()

  if (error) return <div>Error...</div>;

  const { payload } = data.attributes;

  const onAttributesChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { attributes } = getValues();
    const newAttributes = e.target.checked
      ? [...attributes, { uuid: e.target.value }]
      : attributes.filter((attribute) => attribute.uuid !== e.target.value);
    setValue('attributes', newAttributes);
  };

  return (
    <Attributes>
      {payload.map((attribute) => (
        <CheckBox
          label={attribute.name}
          value={attribute.uuid}
          onChange={onAttributesChange}
          control={control}
          name="attributes"
        />
      ))}
    </Attributes>
  );
};
