import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
`;

export const Attributes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 24px;
  padding: 24px 0;
`;
