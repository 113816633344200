import { RouteObject } from 'react-router-dom';
import { CreateAttribute } from './pages/create-attribute';
import { ListAttributes } from './pages/list-attributes';
import { UpdateAttribute } from './pages/update-attribute';
import { paths } from 'src/shared/constants/paths';

export const attributesRoutes: RouteObject[] = [
  { Component: ListAttributes, path: paths.attributes.list() },
  { Component: CreateAttribute, path: paths.attributes.create() },
  { Component: UpdateAttribute, path: paths.attributes.edit() },
];
