import { Button, DataTable, Icon } from '@petmate/ui';
import { PageHeader } from '../../../shared/components/page-header';
import { Wrapper } from './list-categories.styles';
import { Link, useParams } from 'react-router-dom';
import { useProtectedRoute } from 'src/auth/hooks/use-protected-route';
import { Role, useCan } from 'src/auth/hooks/use-can';
import { paths } from 'src/shared/constants/paths';
import { useGetCategoriesQuery } from 'src/categories/gql/queries/get-categories.query';
import { ListCategoriesActions } from './list-categories.actions';
import { columns } from './list-categories.columns';
import { Category } from 'src/shared/types/category.type';

export const ListCategories = () => {
  useProtectedRoute([Role.Admin]);
  const { can } = useCan();
  const params = useParams<{ categoryUuid: string }>();
  const isSubCategory = !!params.categoryUuid;
  const { data, error } = useGetCategoriesQuery({
    variables: {
      filters: {
        parentUuid: { value: params.categoryUuid || null },
      },
      relations: ['children'],
    },
  });

  if (error) return <div>Error...</div>;

  return (
    <Wrapper>
      <PageHeader
        title={isSubCategory ? `Category: ` : 'Categories'}
        canGoBack={isSubCategory}
        actions={[
          can([Role.Admin, Role.Manager]) ? (
            <Button
              as={Link}
              to={paths.categories.create()}
              label="Add category"
              leftComponent={<Icon name="plus" size={20} />}
              size="medium"
            />
          ) : null,
        ]}
      />
      <DataTable<Category>
        columns={columns}
        data={data.categories}
        actions={(row) => <ListCategoriesActions row={row} />}
        keyExtractor={(row) => row.uuid}
      />
    </Wrapper>
  );
};
