import {
  Field,
  FieldErrors,
  FieldValues,
  InternalFieldName,
  ResolverOptions,
  get,
  set,
} from 'react-hook-form';
// import i18n from 'src/shared/locales/i18next';
import { validateFieldsNatively } from './validateFieldsNatively';

const i18n = {
  t: (key: string, values: Record<string, string>) => key,
};

export const toNestErrors = <TFieldValues extends FieldValues>(
  errors: FieldErrors,
  options: ResolverOptions<TFieldValues>
): FieldErrors<TFieldValues> => {
  options.shouldUseNativeValidation && validateFieldsNatively(errors, options);

  const fieldErrors = {} as FieldErrors<TFieldValues>;
  for (const path in errors) {
    const field = get(options.fields, path) as Field['_f'] | undefined;
    const error = Object.assign(errors[path] || {}, {
      ref: field && field.ref,
    });

    if (isNameInFieldArray(options.names || Object.keys(errors), path)) {
      const fieldArrayErrors = Object.assign({}, get(fieldErrors, path));

      set(fieldArrayErrors, 'root', error);
      set(fieldErrors, path, fieldArrayErrors);
    } else {
      set(fieldErrors, path, error);
    }
  }

  return translateFieldErrors(fieldErrors);
};

const translateFieldErrors = (errors: FieldErrors) => {
  const keys = Object.keys(errors);

  return keys.reduce((acc, key) => {
    // @TODO: Remove this type assertion after fixing the type of errors
    // @ts-expect-error Types checking
    const isLocalizationObject = typeof errors[key].message === 'object';
    return {
      ...acc,
      [key]: {
        message: isLocalizationObject
          ? i18n.t(
              // @TODO: Remove this type assertion after fixing the type of errors
              // @ts-expect-error Types checking
              `validation:${errors[key].message.key}`,
              // @TODO: Remove this type assertion after fixing the type of errors
              // @ts-expect-error Types checking
              errors[key].message.values
            )
          : // @TODO: Remove this type assertion after fixing the type of errors
            // @ts-expect-error Types checking
            errors[key].message,
      },
    };
  }, {});
};

const isNameInFieldArray = (
  names: InternalFieldName[],
  name: InternalFieldName
) => names.some((n) => n.startsWith(name + '.'));
