import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 18px;
  color: var(--green-200);
  column-gap: 18px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: var(--white);
    background-color: var(--green-700);
  }
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  width: 100%;

  &.active ${Wrapper} {
    color: var(--white);
    background-color: var(--green-700);
  }
`;


export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: inherit;
`;
