import { TypedDocumentNode, gql, useSuspenseQuery } from '@apollo/client';
import { Category } from 'src/shared/types/category.type';

export interface GetCategoryData {
  category: Category;
}

interface GetCategoryVariables {
  uuid: string;
}

export const GetCategoryQuery: TypedDocumentNode<GetCategoryData> = gql`
  query GetCategory($uuid: String!) {
    category(uuid: $uuid) {
      uuid
      name
      slug
      order
      parentUuid
      published
      attributes {
        uuid
        name
        valueType
      }
      children {
        uuid
        name
        slug
        order
        parentUuid
        published
        children {
          uuid
          name
          slug
          order
          parentUuid
          published
        }
      }
    }
  }
`;

export const useGetCategoryQuery = (variables: GetCategoryVariables) => {
  return useSuspenseQuery<GetCategoryData, GetCategoryVariables>(
    GetCategoryQuery,
    { variables }
  );
};
