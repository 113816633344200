import { useEffect } from 'react';
import slugify from 'slugify';
import { useFormContext } from 'react-hook-form';
import { CategoryPicker } from 'src/categories/components/category-picker';
import { TextField } from 'src/shared/components/textfield';
import { CheckBox } from 'src/shared/components/checkbox';
import { Content } from './info-tab.styles';
import { CategoryFormValues } from 'src/categories/forms/category-form/category-form.types';

export const Info = () => {
  const { watch, setValue, control } = useFormContext<CategoryFormValues>();

  const name$ = watch('name');

  useEffect(() => {
    setValue('slug', slugify(name$, { lower: true }));
  }, [name$, setValue]);

  return (
    <Content>
      <TextField label="Name" name="name" control={control} />
      <TextField label="Slug" name="slug" control={control} />
      <CategoryPicker control={control} name="parentUuid" />
      <TextField label="Order" name="order" control={control} />
      <CheckBox
        label="Published"
        name="published"
        control={control}
        value="true"
      />
    </Content>
  );
};
