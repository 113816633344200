import { ApolloError, TypedDocumentNode, gql, useMutation } from '@apollo/client';

interface SignInData {
  signIn: {
    accessToken: string;
  };
}

interface SignInVariables {
  data: {
    email: string;
    password: string;
  };
}

export const SignInMutation: TypedDocumentNode<
  SignInData,
  SignInVariables
> = gql`
  mutation SignIn($data: SignInInput!) {
    signIn(data: $data) {
      accessToken
    }
  }
`;

export interface UseSignInMutationProps {
  onCompleted?: (data: SignInData) => void;
  onError?: (error: ApolloError) => void;
}

export const useSignInMutation = ({
  onCompleted,
  onError,
}: UseSignInMutationProps) => {
  return useMutation<SignInData, SignInVariables>(SignInMutation, {
    onCompleted,
    onError,
    context: {
      headers: {
        'x-role': 'admin',
      },
    },
  });
};
