import { useQuery } from '@apollo/client';
import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';
import { GetAttributesQuery } from '../../gql/queries/get-attributes.query.gql';
import { Button, DataTable, Icon } from '@petmate/ui';
import { Link, useSearchParams } from 'react-router-dom';
import { paths } from 'src/shared/constants/paths';
import { ListAttributesActions } from './list-attributes.actions';

export type Attribute = {
  uuid: string;
  name: string;
  valueType: string;
};

export const ListAttributes = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page')) || 1;
  const pageSize = Number(searchParams.get('pageSize')) || 10;

  const { data, loading } = useQuery(GetAttributesQuery, {
    variables: { pagination: { page, pageSize } },
  });

  if (loading) return <div>Loading...</div>;

  const { payload, pagination } = data.attributes;

  return (
    <Page>
      <PageHeader
        title="Attributes"
        actions={[
          <Button
            as={Link}
            to={paths.attributes.create()}
            label="Add attribute"
            leftComponent={<Icon name="plus" size={20} />}
            size="medium"
          />,
        ]}
      />
      <DataTable<Attribute>
        data={payload}
        pagination={{ ...pagination, page, pageSize }}
        onPageChange={setSearchParams}
        onPageSizeChange={setSearchParams}
        columns={[
          { accessor: 'name', label: 'Name', width: '85%' },
          { accessor: 'valueType', label: 'Type', width: '15%' },
        ]}
        actions={(row) => <ListAttributesActions row={row} />}
      />
    </Page>
  );
};
