import * as yup from 'yup';

function isEqualTo(field: string) {
  const error = {
    key: 'field_not_match',
    values: {field},
  };
  // @ts-ignore
  return this.oneOf([yup.ref(field), ''], error).required();
}

yup.addMethod(yup.string, 'isEqualTo', isEqualTo);
