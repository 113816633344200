import {fonts} from './fonts';

export type TypographyPaths =
  | 'headline.small'
  | 'headline.medium'
  | 'headline.medium-thin'
  | 'headline.large'
  | 'display.small'
  | 'display.medium'
  | 'display.large'
  | 'overline'
  | 'body.medium'
  | 'body.small'
  | 'body.small-thin'
  | 'caption.medium'
  | 'caption.small'
  | 'caption.small-thin'
  | 'price.large'
  | 'price.medium'
  | 'price.medium-thin'
  | 'price.small'
  | 'discount.medium'
  | 'discount.small'
  | 'button.large'
  | 'button.medium'
  | 'button.small'
  | 'tag.label'
  | 'chip.label'
  | 'forms.checkbox.label.large'
  | 'forms.checkbox.label.medium'
  | 'forms.checkbox.label.small'
  | 'forms.textField.label.medium.static'
  | 'forms.textField.label.medium.active'
  | 'forms.textField.label.small'
  | 'forms.textField.input.medium'
  | 'forms.textField.input.small';

export const typography = {
  headline: {
    small: {
      fontSize: 13,
      lineHeight: 18,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    medium: {
      fontSize: 14,
      lineHeight: 24,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    'medium-thin': {
      fontSize: 14,
      lineHeight: 24,
      fontFamily: fonts.regular,
      fontWeight: 400
    },
    large: {
      fontSize: 16,
      lineHeight: 26,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
  },
  display: {
    small: {
      fontSize: 18,
      lineHeight: 26,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    medium: {
      fontSize: 20,
      lineHeight: 26,
      fontFamily: fonts.semiBold,
      fontWeight: 600
    },
    large: {
      fontSize: 24,
      lineHeight: 32,
      fontFamily: fonts.semiBold,
      fontWeight: 600
    },
  },
  overline: {
    fontSize: 14,
    lineHeight: 26,
    fontFamily: fonts.medium,
    fontWeight: 500
  },
  body: {
    medium: {
      fontSize: 14,
      lineHeight: 24,
      fontFamily: fonts.regular,
      fontWeight: 400
    },
    small: {
      fontSize: 12,
      lineHeight: 18,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    'small-thin': {
      fontSize: 12,
      lineHeight: 18,
      fontFamily: fonts.regular,
      fontWeight: 400
    },
  },
  caption: {
    medium: {
      fontSize: 12,
      lineHeight: 18,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    small: {
      fontSize: 11,
      lineHeight: 16,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    'small-thin': {
      fontSize: 10,
      lineHeight: 16,
      fontFamily: fonts.regular,
      fontWeight: 400
    },
  },
  price: {
    large: {
      fontSize: 18,
      lineHeight: 24,
      fontFamily: fonts.semiBold,
      fontWeight: 600
    },
    medium: {
      fontSize: 14,
      lineHeight: 18,
      fontFamily: fonts.semiBold,
      fontWeight: 600
    },
    'medium-thin': {
      fontSize: 14,
      lineHeight: 18,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    small: {
      fontSize: 13,
      lineHeight: 18,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
  },
  discount: {
    medium: {
      fontSize: 12,
      lineHeight: 16,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    small: {
      fontSize: 10,
      lineHeight: 13,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
  },
  button: {
    large: {
      fontSize: 15,
      lineHeight: 28,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
    medium: {
      fontSize: 14,
      lineHeight: 28,
      fontFamily: fonts.regular,
      fontWeight: 400
    },
    small: {
      fontSize: 12,
      lineHeight: 20,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
  },
  tag: {
    label: {
      fontSize: 11,
      lineHeight: 14,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
  },
  chip: {
    label: {
      fontSize: 12,
      lineHeight: 18,
      fontFamily: fonts.medium,
      fontWeight: 500
    },
  },
  forms: {
    checkbox: {
      label: {
        large: {
          fontSize: 14,
          lineHeight: 24,
          fontFamily: fonts.medium,
          fontWeight: 500
        },
        medium: {
          fontSize: 12,
          lineHeight: 18,
          fontFamily: fonts.regular,
          fontWeight: 400
        },
        small: {
          fontSize: 10,
          lineHeight: 16,
          fontFamily: fonts.regular,
          fontWeight: 400
        },
      },
    },
    textField: {
      label: {
        medium: {
          static: {
            fontSize: 14,
            lineHeight: 26,
            fontFamily: fonts.regular,
            fontWeight: 400
          },
          active: {
            fontSize: 10,
            lineHeight: 16,
            fontFamily: fonts.regular,
            fontWeight: 400
          },
        },
        small: {
          fontSize: 12,
          lineHeight: 24,
          fontFamily: fonts.regular,
          fontWeight: 400
        },
      },
      input: {
        medium: {
          fontSize: 14,
          lineHeight: 26,
          fontFamily: fonts.regular,
          fontWeight: 400
        },
        small: {
          fontSize: 12,
          lineHeight: 24,
          fontFamily: fonts.regular,
          fontWeight: 400
        },
      },
    },
  },
};
