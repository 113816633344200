import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListItem = styled.li<{ $active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  height: 40px;

  ${({ $active }) => $active && 'background-color: var(--gray-100);'}

  &:hover {
    background-color: var(--gray-100);
  }
`;
