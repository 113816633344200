import { RouteObject } from 'react-router-dom';
import { CreateCategory } from './pages/create-category';
import { ListCategories } from './pages/list-categories';
import { UpdateCategory } from './pages/update-category';
import { paths } from 'src/shared/constants/paths';

export const categoriesRoutes: RouteObject[] = [
  { Component: ListCategories, path: paths.categories.list() },
  { Component: ListCategories, path: paths.categories.show() },
  { Component: CreateCategory, path: paths.categories.create() },
  { Component: UpdateCategory, path: paths.categories.edit() },
];
