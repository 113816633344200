import { css } from 'styled-components';
import { typography, TypographyPaths } from './typography';

export const mixins = {
  type: (type: TypographyPaths, color: string) => {
    const parts = type.split('.');

    const typeObject = parts.reduce((acc: any, part) => {
      return acc[part] || {};
    }, typography);

    return css`
      font-size: ${typeObject.fontSize}px;
      line-height: ${typeObject.lineHeight}px;
      font-family: ${typeObject.fontFamily};
      font-weight: ${typeObject.fontWeight};
      color: ${color};
    `;
  },
};
