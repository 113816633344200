import {
  TextField as TextFieldComponent,
  TextFieldProps as TextFieldComponentProps,
} from '@petmate/ui';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface TextFieldProps<T extends FieldValues>
  extends Omit<TextFieldComponentProps, 'value' | 'onBlur' | 'onChange'> {
  control: Control<T>;
  name: Path<T>;
}

export const TextField = <T extends FieldValues>({
  control,
  ...props
}: TextFieldProps<T>) => {
  const { field, fieldState } = useController({ control, name: props.name });
  
  const error = fieldState.error?.message || undefined;
  return (
    <TextFieldComponent
      {...props}
      {...field}
      error={error}
    />
  );
};
