import { TypedDocumentNode, gql, useSuspenseQuery } from '@apollo/client';
import { Attribute } from 'src/shared/types/attribute.type';

interface GetAttributesData {
  attributes: {
    payload: Attribute[];
  };
}

export const GetAttributesQuery: TypedDocumentNode<GetAttributesData> = gql`
  query GetAttributesQuery {
    attributes {
      payload {
        uuid
        name
        valueType
      }
    }
  }
`;

export const useGetAttributesQuery = () => {
  return useSuspenseQuery<GetAttributesData>(GetAttributesQuery);
};
