import {
  ApolloError,
  TypedDocumentNode,
  gql,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { useNotifications } from '@petmate/ui';
import { GetCategoryQuery } from '../queries/get-category.query';
import { GetRootCategoriesQuery } from '../queries/get-root-categories.query';

interface CreateCategoryData {
  createCategory: {
    uuid: string;
    name: string;
  };
}

interface CreateCategoryVariables {
  data: {
    name: string;
    // description: string;
    slug: string;
    order: number;
    published: boolean;
    attributes: { uuid: string }[];
    parentUuid?: string;
  };
}

export const CreateCategoryMutation: TypedDocumentNode<
  CreateCategoryData,
  CreateCategoryVariables
> = gql`
  mutation CreateCategoryMutation($data: CreateCategoryInput!) {
    createCategory(data: $data) {
      uuid
      name
    }
  }
`;

export interface CreateCategoryMutationOptions {
  onCompleted?: (data: CreateCategoryData) => void;
  onError?: (error: ApolloError) => void;
}

export const useCreateCategoryMutation = ({
  onCompleted,
  onError,
}: CreateCategoryMutationOptions) => {
  const { success, error } = useNotifications();
  const client = useApolloClient();

  return useMutation<CreateCategoryData, CreateCategoryVariables>(
    CreateCategoryMutation,
    {
      onCompleted(data) {
        client.refetchQueries({include: [GetRootCategoriesQuery, GetCategoryQuery]});
        onCompleted?.(data);
        success({
          title: 'Success',
          message: `Category ${data.createCategory.name} created successfully!`,
        });
      },
      onError(e) {
        onError?.(e);
        error({ title: 'Error', message: 'An error occurred!' });
      },
    }
  );
};
