import { UpdateAttributeVariables } from 'src/attributes/gql/mutations/update-attribute.mutation';
import { AttributeFormValues } from './attribute-form.types';

export type UpdateAttributeVariablesData = UpdateAttributeVariables['data'];

export const formValuesToApiData = (
  values: AttributeFormValues
): UpdateAttributeVariablesData => ({
  name: values.name,
  valueType: values.valueType,
  values: values.values.map((value) => ({
    uuid: value.uuid,
    value: value.value,
  })),
  description: '',
});

export const apiDataToFormValues = (
  data: UpdateAttributeVariablesData
): AttributeFormValues => ({
  name: data.name,
  valueType: data.valueType,
  values: data.values.map((value) => ({
    uuid: value.uuid,
    value: value.value,
  })),
  description: data.description,
});
