import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 44px;
  flex-wrap: wrap;
  row-gap: 8px;

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input`
  ${({ theme }) =>
    theme.mixins.type('forms.textField.label.medium.static', 'var(--gray-900)')}

  flex: 1;
  height: 100%;
  border-radius: 8px;

  &::placeholder {
    ${({ theme }) =>
      theme.mixins.type(
        'forms.textField.label.medium.static',
        'var(--gray-600)'
      )}
  }

  &:focus {
    outline: none;
  }
`;

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  ${({ theme }) => theme.mixins.type('tag.label', 'var(--green-900)')}

  background-color: var(--green-50);
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  margin-right: 8px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;
