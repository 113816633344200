import { TypedDocumentNode, gql, useSuspenseQuery } from '@apollo/client';
import { Category } from 'src/shared/types/category.type';

export interface GetRootCategoriesData {
  rootCategories: Category[];
}

export const GetRootCategoriesQuery: TypedDocumentNode<GetRootCategoriesData> = gql`
  fragment CategoryFragment on Category {
    uuid
    name
    order
    published
  }

  query GetRootCategories {
    rootCategories {
      ...CategoryFragment
      children {
        ...CategoryFragment
        children {
          ...CategoryFragment
        }
      }
    }
  }
`;

export const useGetRootCategoriesQuery = () => {
  return useSuspenseQuery<GetRootCategoriesData>(GetRootCategoriesQuery);
};
