import { ApolloError } from '@apollo/client';

export interface NetworkError {
    error: string;
    message: string | Record<string, string | string[]>;
    statusCode: number;
}

export const getGqlError = (error: ApolloError): NetworkError | null => {
  const errorMessages = error.graphQLErrors.map((graphQLError) => {
    return graphQLError.extensions.originalError;
  }) as NetworkError[];

  if(errorMessages.length === 0) return null
  return errorMessages[0]
};
