import { List, ListItem, Wrapper } from './category-picker.styles';
import { Icon } from '@petmate/ui';
import { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { CategoryFormValues } from 'src/categories/forms/category-form/category-form.types';
import { useGetRootCategoriesQuery } from 'src/categories/gql/queries/get-root-categories.query';
import { Category } from 'src/shared/types/category.type';
import { findPathToCategory, getCategory } from './category-picker.utils';

export interface CategoryPickerProps {
  control: Control<CategoryFormValues>;
  name: keyof CategoryFormValues;
}

export const CategoryPicker = ({ control, name }: CategoryPickerProps) => {
  const { field } = useController({ control, name });
  const { data } = useGetRootCategoriesQuery();
  const [path, setPath] = useState<number[]>([]);

  useEffect(() => {
    if (field.value && data) {
      const path = findPathToCategory(data.rootCategories, {
        uuid: field.value,
      } as Category) as number[];
      setPath(path);
    }
  }, [data, field.value]);

  const handleItemClick = (category: Category, idx: number, level: number) => {
    setPath((prevPath) => {
      const newPath = prevPath.slice(0, level);
      newPath[level] = idx;
      return newPath;
    });
    field.onChange(category.uuid);
  };

  return (
    <Wrapper>
      <List>
        {data.rootCategories.map((category, idx) => (
          <ListItem
            $active={path[0] === idx}
            onClick={() => handleItemClick(category, idx, 0)}
          >
            {category.name} <Icon name="chevronRight" size={16} />
          </ListItem>
        ))}
      </List>
      {path.map((idx, level) => {
        const categories = getCategory(
          data.rootCategories,
          path.slice(0, level + 1)
        );
        if (!categories) return null;
        return (
          <List>
            {categories.map((category, idx) => (
              <ListItem
                $active={path[level + 1] === idx}
                onClick={() => handleItemClick(category, idx, level + 1)}
              >
                {category.name}
                {category.children && <Icon name="chevronRight" size={16} />}
              </ListItem>
            ))}
          </List>
        );
      })}
    </Wrapper>
  );
};
