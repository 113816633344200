import { PageHeader } from 'src/shared/components/page-header';
import { UserForm, UserFormValues } from 'src/users/forms/user-form/user-form';
import { Wrapper } from './create-user.styles';
import { useApolloClient, useMutation } from '@apollo/client';
import { CreateUserMutation } from '../../gql/mutations/create-user.mutation.gql';
import { GetUsersQuery } from '../../gql/queries/get-users.query.gql';
import { useState } from 'react';
import { useNotifications } from '@petmate/ui';
import { getValidationErrors } from 'src/shared/utils/get-validation-errors';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/shared/constants/paths';

export const CreateUser = () => {
  const [errors, setErrors] = useState<Record<string, string> | undefined>(
    undefined
  );
  const { error, success } = useNotifications();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [createUser] = useMutation(CreateUserMutation, {
    onCompleted: () => {
      success({
        title: 'Success',
        message: 'User created successfully!',
      });
      client.refetchQueries({ include: [GetUsersQuery] });
      navigate(paths.users.list());
    },
    onError: (e) => {
      error({
        title: 'Error',
        message: 'An error occurred while creating the user',
      });
      setErrors(getValidationErrors(e));
    },
  });

  const handleCreateUser = (values: UserFormValues) => {
    createUser({
      variables: {
        data: {
          email: values.email,
          password: values.password,
          role: values.role,
          profile: {
            firstName: values.profile.firstName,
            lastName: values.profile.lastName,
            phone: values.profile.phone,
          },
        },
      },
    });
  };

  return (
    <Wrapper>
      <PageHeader title="Create user" />
      <UserForm
        submitLabel="Create user"
        errors={errors}
        onSubmit={handleCreateUser}
      />
    </Wrapper>
  );
};
