import { Wrapper } from './attributes-picker.styles';
import { Attribute } from '../../../attributes/pages/list-attributes/list-attributes';
import { AttributesResults } from './attributes-results';
import { Suspense } from 'react';

export interface GetAttributesQueryResponse {
  attributes: {
    payload: Attribute[];
  };
}

export const AttributesPicker = () => {
  return (
    <Suspense>
      <Wrapper>
        <AttributesResults />
      </Wrapper>
    </Suspense>
  );
};
