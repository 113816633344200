import { useSuspenseQuery } from '@apollo/client';
import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useEffect,
} from 'react';
import { WhoAmIQuery } from '../api/queries/who-am-i.query';

const initialValues: AuthContextState = {
  authenticated: false,
  loading: true,
  user: {},
  authenticate: () => {},
  logout: () => {},
};

export interface AuthContextState {
  authenticated: boolean;
  loading: boolean;
  user?: any;
  authenticate: () => void;
  logout: () => void;
}

export const AuthContext = createContext(initialValues);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<null | unknown>(null);
  const {
    data,
    error,
    refetch: revalidate,
  } = useSuspenseQuery(WhoAmIQuery, {
    errorPolicy: 'ignore',
  });

  useEffect(() => {
    if (data?.whoAmI) {
      setUser(data.whoAmI);
    }
    if (error) {
      setUser(null);
    }
    setLoading(false);
  }, [data, error]);

  const authenticate = () => {
    revalidate();
  };

  const logout = () => {
    document.cookie =
      'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        authenticated: !!user,
        authenticate,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
