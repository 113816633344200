import { useEffect } from 'react';
import { useAuthContext } from '../context/auth.context';
import { useNavigate } from 'react-router-dom';
import { Role } from './use-can';
import { useNotifications } from '@petmate/ui';
import { paths } from 'src/shared/constants/paths';

export const useProtectedRoute = (role?: Role | Role[]) => {
  const { authenticated, loading, user } = useAuthContext();
  const navigate = useNavigate();
  const { error } = useNotifications();
  useEffect(() => {
    if (!authenticated && !loading) {
      const redirect = window.location.pathname;
      if (redirect === paths.auth.login()) return;
      navigate(paths.auth.login(`redirect=${redirect}`));
      return;
    }

    if (user && role && !role.includes(user.role.name) && !loading) {
      error({
        title: 'Unauthorized',
        message: 'You are not authorized to access this page',
      });
      navigate(paths.dashboard());
    }
  }, [authenticated, user, role, error, navigate, loading]);
};
