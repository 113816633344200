import {DefaultTheme} from 'styled-components/native';
import {colors} from './colors';
import {fonts} from './fonts';
import {shadows} from './shadows';
import {typography} from './typography';
import { mixins } from './mixins';

export const theme: DefaultTheme = {
  colors,
  fonts,
  typography,
  shadows,
  mixins
};
