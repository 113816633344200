import styled from 'styled-components';

export const Section = styled.section`
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  & > * {
    flex: 1;
  }
`;

export const Fields = styled.div`
  display: grid;
  gap: 16px;
  padding: 16px 0 0 0;
`;

export const Header = styled.header`
  padding: 16px 0 16px 0;
  border-bottom: 1px solid var(--gray-300);
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: var(--gray-900);
  line-height: 28px;
`;
export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0 0 0;
`;
