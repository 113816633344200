export const colors = {
  grayscale: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    white: '#ffffff',
    black: '#000000',
  },
  green: {
    50: '#E6F5ED',
    100: '#C3E6D2',
    200: '#9CD5B5',
    300: '#74C699',
    400: '#54B984',
    500: '#31AD6F',
    600: '#2B9E64',
    700: '#238C58',
    800: '#1E7B4C',
    900: '#165B38',
  },
  orange: {
    50: '#FBF3E2',
    100: '#F9DFB1',
    200: '#F2CB8A',
    300: '#EDB65E',
    400: '#EAA741',
    500: '#E8992A',
    600: '#E48E27',
    700: '#DE7F22',
    800: '#D7711D',
    900: '#CE5A14',
  },
  navy: {
    50: '#E8EAEF',
    100: '#C4CADA',
    200: '#9FA8C1',
    300: '#7C86A7',
    400: '#616D95',
    500: '#475485',
    600: '#404D7D',
    700: '#384371',
    800: '#313A64',
    900: '#262A4C',
  },
  blue: {
    50: '#E5F1F7',
    100: '#BFDCEC',
    200: '#9AC7E0',
    300: '#79B1D3',
    400: '#62A1CB',
    500: '#5092C4',
    600: '#4685B7',
    700: '#3B74A6',
    800: '#336494',
    900: '#1C487A',
  },
  red: {
    600: '#EB5757',
    700: '#D14D4D',
  },
};
