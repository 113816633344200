import { Category } from 'src/shared/types/category.type';

export const getCategory = (categories: Category[], path: number[]) => {
  return path.reduce((acc, idx) => {
    return acc[idx].children as Category[];
  }, categories);
};

export const findPathToCategory = (
  categories: Category[],
  target: Category,
  path: number[] = []
): number[] | null => {
  for (let i = 0; i < categories.length; i++) {
    const currentCategory = categories[i];
    if (currentCategory.uuid === target.uuid) {
      return [...path, i];
    }
    if (currentCategory.children) {
      const foundPath = findPathToCategory(currentCategory.children, target, [
        ...path,
        i,
      ]);
      if (foundPath) return foundPath;
    }
  }
  return null; // Target category not found
};
