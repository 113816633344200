import { Button, Tabs } from '@petmate/ui';
import { FormProvider, useForm } from 'react-hook-form';

import { AttributesPicker } from '../../components/attributes-picker';
import { CategoryFormValues } from './category-form.types';
import { Info } from '../../components/info-tab';
import { Suspense } from 'react';
import { validation } from './validation';
import { yupResolver } from 'src/shared/validation/resolver/yup';

export interface CategoryFormProps {
  onSubmit: (data: CategoryFormValues) => void;
  values?: CategoryFormValues;
  submitLabel: string;
  pending: boolean;
}

const defaultValues: CategoryFormValues = {
  name: '',
  order: 1,
  published: false,
  slug: '',
  parentUuid: '',
  attributes: [],
};

export const CategoryForm = ({
  onSubmit,
  pending,
  submitLabel,
  values,
}: CategoryFormProps) => {
  const form = useForm<CategoryFormValues>({
    values: values || defaultValues,
    resolver: yupResolver(validation),
  });

  const onSubmitHandle = (data: CategoryFormValues) => {
    const parsedAttributes = Object.entries(data.attributes).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.push(value);
        }
        return acc;
      },
      [] as { uuid: string }[]
    );

    onSubmit({
      ...data,
      attributes: parsedAttributes,
    });
  };

  return (
    <Suspense>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmitHandle)}>
          <Tabs
            mode="content"
            items={[
              {
                key: 'info',
                label: 'Informacje',
                component: <Info />,
              },
              {
                key: 'attributes',
                label: 'Atrybuty',
                component: <AttributesPicker />,
              },
            ]}
          />

          <Button pending={pending} label={submitLabel} size="medium" />
        </form>
      </FormProvider>
    </Suspense>
  );
};
