import { NavigationItem, NavigationItemProps } from '../navigation-item';
import { Wrapper } from './navigation.styles';
import { useLayoutContext } from '../../context/layout.context';
export interface NavigationProps {
  items: NavigationItemProps[];
}

export const Navigation = ({ items }: NavigationProps) => {
  const { drawerExpanded } = useLayoutContext();

  return (
    <Wrapper $expanded={drawerExpanded}>
      {items.map((item, index) => (
        <NavigationItem key={index} {...item} />
      ))}
    </Wrapper>
  );
};
