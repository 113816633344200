import { useForm } from 'react-hook-form';
import { Content, Form, Heading, LogoImage, Wrapper } from './login.styles';
import { TextField } from 'src/shared/components/textfield';
import { Button } from '@petmate/ui';
import Logo from 'src/shared/assets/images/logo.svg';
import { useSignInMutation } from 'src/auth/api/mutations/sign-in.mutation';
import { useAuthContext } from 'src/auth/context/auth.context';
import { useNavigate } from 'react-router-dom';
import { getUrlParams } from 'src/shared/utils/get-url-params';
import { useEffect, useState } from 'react';
import { getValidationErrors } from 'src/shared/utils/get-validation-errors';
import { getGqlError } from 'src/shared/utils/get-gql-error';

export const Login = () => {
  const { authenticated, authenticate, user } = useAuthContext();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  const { control, handleSubmit, setError } = useForm({
    values: { email: '', password: '' },
  });

  const [signIn] = useSignInMutation({
    onCompleted() {
      authenticate();
    },
    onError(error) {
      const validationErrors = getValidationErrors(error);
      if (validationErrors) {
        setErrors(validationErrors);
      }
      const parsedError = getGqlError(error);
      if (parsedError?.statusCode === 401) {
        setError('email', {
          type: 'manual',
          message: 'Invalid email or password',
        });
      }
    },
  });

  useEffect(() => {
    if (user) {
      const urlParams = getUrlParams();
      navigate(urlParams.get('redirect') || '/');
    }
  }, [authenticated, user, navigate]);

  const login = async (data: { email: string; password: string }) => {
    await signIn({ variables: { data } });
  };

  return (
    <Wrapper>
      <LogoImage src={Logo} alt="Petmate" />
      <Content>
        <Form onSubmit={handleSubmit(login)}>
          <Heading>Login</Heading>
          <TextField label="Email addres" name="email" control={control} />
          <TextField label="Password" name="password" control={control} />
          <Button type="submit" label="Login" />
        </Form>
      </Content>
    </Wrapper>
  );
};
