import {LocaleObject} from 'yup';

export const yupLocale: LocaleObject = {
  mixed: {
    default: () => ({key: 'field_invalid', values: {}}),
    required: () => ({key: 'field_required', values: {}}),
    oneOf: () => ({key: 'should_be_one_of', values: {}}),
    notOneOf: () => ({key: 'field_invalid', values: {}}),
  },
  string: {
    length: ({length}) => ({key: 'field_length', values: {length}}),
    min: ({min}) => ({key: 'field_too_short', values: {min}}),
    max: ({max}) => ({key: 'field_too_long', values: {max}}),
    email: () => ({key: 'email_invalid', values: {}}),
    url: () => ({key: 'url_invalid', values: {}}),
    trim: () => ({key: 'field_invalid', values: {}}),
    lowercase: () => ({key: 'should_be_lowercase', values: {}}),
    uppercase: () => ({key: 'should_be_uppercase', values: {}}),
    // @TODO: Solve typescript declaration issue
    // @ts-ignore
    isEqualTo: ({field}) => ({key: 'field_not_match', values: {field}}),
  },
  number: {
    min: ({min}) => ({key: 'should_be_minimum', values: {min}}),
    max: ({max}) => ({key: 'should_be_maximum', values: {max}}),
    lessThan: ({less}) => ({key: 'should_be_less_than', values: {less}}),
    moreThan: ({more}) => ({key: 'should_be_more_than', values: {more}}),
    positive: () => ({key: 'should_be_positive', values: {}}),
    negative: () => ({key: 'should_be_negative', values: {}}),
    integer: () => ({key: 'should_be_integer', values: {}}),
  },
  date: {
    min: ({min}) => ({key: 'should_be_after', values: {min}}),
    max: ({max}) => ({key: 'should_be_before', values: {max}}),
  },
  boolean: {
    isValue: () => ({key: 'field_invalid', values: {}}),
  },
  object: {
    noUnknown: () => ({key: 'field_invalid', values: {}}),
  },
  array: {
    length: ({length}) => ({key: 'field_length', values: {length}}),
    min: ({min}) => ({key: 'field_too_short', values: {min}}),
    max: ({max}) => ({key: 'field_too_big', values: {max}}),
  },
};
