import { PageHeader } from 'src/shared/components/page-header';
import { UserForm, UserFormValues } from 'src/users/forms/user-form/user-form';
import { Wrapper } from './update-user.styles';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { GetUserQuery } from 'src/users/gql/queries/get-user.query.gql';
import { GetUsersQuery } from 'src/users/gql/queries/get-users.query.gql';
import { UpdateUserMutation } from 'src/users/gql/mutations/update-user.mutation.gql';
import { useState } from 'react';
import { getValidationErrors } from 'src/shared/utils/get-validation-errors';
import { useNotifications } from '@petmate/ui';

export const UpdateUser = () => {
  const [errors, setErrors] = useState<Record<string, string> | undefined>(
    undefined
  );
  const { success, error } = useNotifications();
  const params = useParams<{ uuid: string }>();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GetUserQuery, {
    variables: { uuid: params.uuid },
  });
  const [updateUser] = useMutation(UpdateUserMutation, {
    onCompleted() {
      success({
        title: 'Success',
        message: 'User updated successfully!',
      });
      client.refetchQueries({ include: [GetUsersQuery, GetUserQuery] });
      navigate('/users');
    },
    onError(e) {
      error({
        title: 'Error',
        message: 'An error occurred while updating the user',
      });
      setErrors(getValidationErrors(e));
    },
  });

  const filterChangedValues = (obj: any, originalObj: any) => {
    const changedValues: any = {};
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const nestedChanges = filterChangedValues(obj[key], originalObj[key]);
        if (Object.keys(nestedChanges).length > 0) {
          changedValues[key] = nestedChanges;
        }
      } else if (obj[key] !== originalObj[key]) {
        changedValues[key] = obj[key];
      }
    }

    return changedValues;
  };

  const handleOnSubmit = (values: UserFormValues) => {
    const {password_confirmation, ...userFormValues} = values
    updateUser({
      variables: {
        data: filterChangedValues(userFormValues, data.user),
        uuid: params.uuid,
      },
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <PageHeader canGoBack title="Update user" />
      <UserForm
        submitLabel="Update user"
        onSubmit={handleOnSubmit}
        errors={errors}
        values={data.user}
      />
    </Wrapper>
  );
};
