import * as yup from 'yup';

export const validation = yup.object().shape({
  name: yup.string().required(),
  slug: yup.string().required(),
  order: yup.number().required(),
  published: yup.boolean().required(),
  attributes: yup.array().of(
    yup.object().shape({
      uuid: yup.string().required(),
    })
  ).required(),
});
