import {
  ApolloError,
  TypedDocumentNode,
  gql,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { useNotifications } from '@petmate/ui';
import { GetAttributesQuery } from 'src/categories/gql/queries/get-attributes.query';

interface CreateAttributeData {
  createAttribute: {
    uuid: string;
    name: string;
    valueType: string;
  };
}

interface CreateAttributeVariables {
  data: {
    name: string;
    description: string;
    valueType: string;
    values: { uuid?: string; value: string }[];
  };
}

export const CreateAttributeMutation: TypedDocumentNode<
  CreateAttributeData,
  CreateAttributeVariables
> = gql`
  mutation CreateAttribute($data: CreateAttributeInput!) {
    createAttribute(data: $data) {
      uuid
      name
      valueType
    }
  }
`;

export interface CreateAttributeMutationOptions {
  onCompleted?: (data: CreateAttributeData) => void;
  onError?: (error: ApolloError) => void;
}

export const useCreateAttributeMutation = ({
  onCompleted,
  onError,
}: CreateAttributeMutationOptions) => {
  const { success, error } = useNotifications();
  const client = useApolloClient();

  return useMutation<CreateAttributeData, CreateAttributeVariables>(
    CreateAttributeMutation,
    {
      onCompleted(data) {
        onCompleted?.(data);
        success({
          title: 'Success',
          message: `Attribute ${data.createAttribute.name} created successfully!`,
        });
        client.refetchQueries({ include: [GetAttributesQuery] });
      },
      onError(e) {
        onError?.(e);
        error({
          title: 'Error',
          message: 'An error occurred while creating the attribute',
        });
      },
    }
  );
};
