import React from 'react';
import { createContext, PropsWithChildren, useState } from 'react';

export interface LayoutContextState {
  drawerExpanded: boolean;
  expandDrawer(): void;
  collapseDrawer(): void;
  toggleDrawer(): void;
}

export const initialState: LayoutContextState = {
  drawerExpanded: false,
  expandDrawer: () => {},
  collapseDrawer: () => {},
  toggleDrawer: () => {},
};

export const LayoutContext = createContext(initialState);

export const Provider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState(initialState);

  const expandDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      drawerExpanded: true,
    }));
  };

  const collapseDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      drawerExpanded: false,
    }));
  };

  const toggleDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      drawerExpanded: !prevState.drawerExpanded,
    }));
  };

  return (
    <LayoutContext.Provider value={{ ...state, expandDrawer, collapseDrawer, toggleDrawer }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  return React.useContext(LayoutContext);
};
