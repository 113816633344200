import {colors} from './colors';

export const shadows = {
  productCard: {
    borderRadius: 16,
    shadowColor: colors.grayscale.black,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.04,
    shadowRadius: 20,
  },
  tabBar: {
    shadowColor: colors.grayscale.black,
    shadowOffset: {
      width: 0,
      height: -8,
    },
    shadowOpacity: 0.04,
    shadowRadius: 20,
  },
  rangeSliderHandle: {
    shadowColor: colors.grayscale.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 4,
  },
  small: {
    shadowColor: colors.grayscale.black,
    shadowOffset: {
      width: 20,
      height: 20,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
  },
};
