import { ApolloError } from '@apollo/client';
import { getGqlError } from './get-gql-error';

export const getValidationErrors = (error: ApolloError) => {
  const parsedError = getGqlError(error);

  if (!parsedError || typeof parsedError.message !== 'object') return undefined;
  const formattedErrors: Record<string, string> = {};

  const validationErrors = parsedError.message as Record<
    string,
    string | string[]
  >;

  for (const key in validationErrors) {
    if (Array.isArray(validationErrors[key])) {
      formattedErrors[key] = validationErrors[key][0];
    } else {
      formattedErrors[key] = validationErrors[key] as string;
    }
  }

  return formattedErrors;
};
