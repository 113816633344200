import { useApolloClient } from '@apollo/client';
import { ButtonGroup, ConfirmModal, IconButton } from '@petmate/ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRemoveAttributeMutation } from 'src/attributes/gql/mutations/remove-attribute.mutation';
import { GetAttributesQuery } from 'src/categories/gql/queries/get-attributes.query';
import { paths } from 'src/shared/constants/paths';
import { Attribute } from 'src/shared/types/attribute.type';

export const ListAttributesActions = ({ row }: { row: Attribute }) => {
  const client = useApolloClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [mutate] = useRemoveAttributeMutation({
    onCompleted() {
      client.refetchQueries({ include: [GetAttributesQuery] });
    },
  });

  const handleModalSubmit = () => {
    setModalOpen(false);
    mutate({ variables: { uuid: row.uuid } });
  };

  return (
    <ButtonGroup>
      <IconButton
        as={Link}
        to={paths.attributes.show(row.uuid)}
        iconSize={16}
        variant="info"
        name="eye"
      />
      <IconButton
        as={Link}
        to={paths.attributes.edit(row.uuid)}
        iconSize={16}
        variant="warning"
        name="edit"
      />
      <IconButton
        iconSize={16}
        variant="error"
        name="trash"
        onClick={() => setModalOpen(true)}
      />
      <ConfirmModal
        cancelText="Nie, zostaw"
        confirmText="Tak, usuń"
        title={`Czy na pewno chcesz usunąć atrybut: ${row.name}?`}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleModalSubmit}
        open={modalOpen}
      />
    </ButtonGroup>
  );
};
