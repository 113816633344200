import styled from 'styled-components';

export const Wrapper = styled.ul<{ $expanded: boolean }>`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;

  height: 100%;
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease-in-out;
  margin-top: 64px;
`;


