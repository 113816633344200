import {
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  gql,
  useSuspenseQuery,
} from '@apollo/client';
import { Category } from 'src/shared/types/category.type';

import { FilterInput } from 'src/shared/types/filter-input.type';

interface GetCategoriesVariables {
  filters?: {
    parentUuid?: FilterInput;
  };
  relations?: string[];
}

interface GetCategoriesData {
  categories: Category[];
}

export const GetCategoriesQuery: TypedDocumentNode<GetCategoriesData> = gql`
  query GetCategoriesQuery(
    $filters: CategoriesFiltersInput
    $relations: [String!]
  ) {
    categories(filters: $filters, relations: $relations) {
      uuid
      name
      order
      children {
        uuid
      }
    }
  }
`;

export interface UseGetCategoriesQuery extends SuspenseQueryHookOptions {
  variables?: GetCategoriesVariables;
}

export const useGetCategoriesQuery = (options: UseGetCategoriesQuery = {}) => {
  console.log(options, 'tu ');
  return useSuspenseQuery<GetCategoriesData, GetCategoriesVariables>(
    GetCategoriesQuery,
    options
  );
};
