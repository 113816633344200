import {
  ApolloError,
  TypedDocumentNode,
  gql,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { useNotifications } from '@petmate/ui';
import { GetRootCategoriesQuery } from '../queries/get-root-categories.query';
import { GetCategoryQuery } from '../queries/get-category.query';

interface UpdateCategoryData {
  updateCategory: {
    uuid: string;
    name: string;
    order: number;
    parentUuid: string;
    published: boolean;
  };
}

export interface UpdateCategoryVariables {
  uuid: string;
  data: {
    name: string;
    // description: string;
    slug: string;
    order: number;
    published: boolean;
    attributes: { uuid: string }[];
    parentUuid?: string;
  };
}

export const UpdateCategoryMutation: TypedDocumentNode<
  UpdateCategoryData,
  UpdateCategoryVariables
> = gql`
  mutation UpdateCategoryMutation($uuid: String!, $data: UpdateCategoryInput!) {
    updateCategory(uuid: $uuid, data: $data) {
      uuid
      name
      order
      parentUuid
      published
    }
  }
`;

export interface UpdateCategoryMutationOptions {
  onCompleted?: (data: UpdateCategoryData) => void;
  onError?: (error: ApolloError) => void;
}

export const useUpdateCategoryMutation = ({
  onCompleted,
  onError,
}: UpdateCategoryMutationOptions) => {
  const { success, error } = useNotifications();
  const client = useApolloClient();

  return useMutation<UpdateCategoryData, UpdateCategoryVariables>(
    UpdateCategoryMutation,
    {
      onCompleted(data) {
        client.refetchQueries({
          include: [GetRootCategoriesQuery, GetCategoryQuery],
        });
        onCompleted?.(data);
        success({
          title: 'Success',
          message: `Category ${data.updateCategory.name} updated successfully!`,
        });
      },
      onError(e) {
        onError?.(e);
        error({ title: 'Error', message: 'An error occurred!' });
      },
    }
  );
};
