import {
  Select as SelectComponent,
  SelectProps as SelectComponentProps,
} from '@petmate/ui';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface SelectProps<T extends FieldValues>
  extends Omit<SelectComponentProps, 'value' | 'onChange'> {
  control: Control<T>;
  name: Path<T>;
}

export const Select = <T extends object>(props: SelectProps<T>) => {
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
  });

  const error = fieldState.error?.message || undefined;
  return (
    <SelectComponent {...props} {...field} error={error} />
  );
};
