import { Label, Link, Wrapper } from './navigation-item.styles';
import { Icon } from '@petmate/ui';

export interface NavigationItemProps {
  label: string;
  url: string;
  icon: string;
  visible?: boolean;
  onClick?: () => void;
}

export const NavigationItem = ({
  label,
  url,
  icon,
  visible,
  onClick,
}: NavigationItemProps) => {
  if (visible === false) {
    return null;
  }
  return (
    <Link
      to={url}
      onClick={onClick}
    >
      <Wrapper>
        <div>
          <Icon name={icon as any} size={24} />
        </div>
        <Label>{label}</Label>
      </Wrapper>
    </Link>
  );
};
