import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';
import { AttributeForm } from '../../forms/attribute-form';
import { useNavigate, useParams } from 'react-router-dom';
import GetAttributeQuery from '../../gql/queries/get-attribute.query.gql';
import GetAttributesQuery from '../../gql/queries/get-attributes.query.gql';
import { useApolloClient, useQuery } from '@apollo/client';
import { useUpdateAttributeMutation } from 'src/attributes/gql/mutations/update-attribute.mutation';
import { paths } from 'src/shared/constants/paths';
import {
  apiDataToFormValues,
  formValuesToApiData,
} from 'src/attributes/forms/attribute-form/attribute-form.mappers';

export const UpdateAttribute = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data, loading } = useQuery(GetAttributeQuery, {
    variables: { uuid },
  });
  const navigate = useNavigate();
  const queryClient = useApolloClient();
  const [mutate] = useUpdateAttributeMutation({
    onCompleted: () => {
      queryClient.refetchQueries({ include: [GetAttributesQuery] });
      navigate(paths.attributes.list());
    },
  });

  if (loading) {
    return <Page>Loading...</Page>;
  }

  return (
    <Page>
      <PageHeader title={`Update attribute: ${data.attribute.name}`} />
      <AttributeForm
        onSubmit={(values) => {
          mutate({
            variables: {
              uuid: String(uuid),
              data: formValuesToApiData(values),
            },
          });
        }}
        submitLabel="Update"
        values={apiDataToFormValues(data.attribute)}
      />
    </Page>
  );
};
