import {
  CheckBox as CheckBoxComponent,
  CheckBoxProps as CheckBoxComponentProps,
} from '@petmate/ui';
import { ChangeEventHandler } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface CheckBoxProps<T extends FieldValues>
  extends Omit<CheckBoxComponentProps, 'onBlur' | 'onChange'> {
  control: Control<T>;
  name: Path<T>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const CheckBox = <T extends FieldValues>({
  control,
  ...props
}: CheckBoxProps<T>) => {
  const { field, fieldState } = useController({ control, name: props.name });
  const error = fieldState.error?.message || undefined;
  const checked =
    field.value === true ||
    (Array.isArray(field.value) &&
      field.value.find((v) => v.uuid === props.value));

  return (
    <CheckBoxComponent
      {...field}
      {...props}
      checked={checked}
      onChange={props.onChange || field.onChange}
    />
  );
};
