import { RouteObject } from 'react-router-dom';
import { ListUsers } from './pages/list-users/list-users';
import { CreateUser } from './pages/create-user';
import { UpdateUser } from './pages/update-user';
import { UserDetails } from './pages/user-details/user-details';
import { paths } from 'src/shared/constants/paths';

export const usersRoutes: RouteObject[] = [
  { Component: ListUsers, path: paths.users.list() },
  { Component: UserDetails, path: paths.users.show() },
  { Component: CreateUser, path: paths.users.create() },
  { Component: UpdateUser, path: paths.users.edit() },
];
