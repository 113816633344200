import * as yup from 'yup';

export const validation = yup.object().shape({
  name: yup.string().required(),
  valueType: yup
    .mixed()
    .oneOf(['string', 'number', 'boolean', 'dictionary'])
    .required(),
  values: yup.array().when('valueType', (valueType: any, schema) => {
    if (valueType[0] === 'dictionary') {
      return schema
        .of(
          yup.object().shape({
            value: yup.string().required(),
          })
        )
        .min(1);
    }
    return schema.notRequired();
  }),
  description: yup.string().optional(),
});
