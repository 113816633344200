import { TypedDocumentNode, gql } from '@apollo/client';

interface WhoAmIData {
  whoAmI: {
    uuid: string;
    profile: {
      initialSetupCompleted: boolean;
    };
  };
}

export const WhoAmIQuery: TypedDocumentNode<WhoAmIData> = gql`
  query WhoAmI {
    whoAmI {
      uuid
      email
      profile {
        firstName
        lastName
        phone
        initialSetupCompleted
      }
      role {
        uuid
        name
        displayName
      }
    }
  }
`;
