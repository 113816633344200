import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 20px;
  row-gap: 32px;
`;

export const Name = styled(Link)`
  display: block;
  text-decoration: none;
  color: var(--gray-900);
`;
